iframe {
  position: static !important;
}

.makeStyles-root-9 {
  background-color: #fff;
  max-height: 250px;
}
.MuiPopover-paper {
  width: auto !important;
}
.custom-tooltip {
  /* border-color: #f843a7; */
  background-color: #f8f8f8;
  /* border:1px #d32ad3 solid ; */
  /* opacity: 0.9; */
  border-radius: 2px;
  box-shadow: 0px 8px 8px #bbb8b8;
  padding: 3px;
  font-size: 9px;
  z-index: 1;
  /* font-weight: 900; */
}
.pieLabel{
  margin: 10px 0;
  color: rgb(41, 41, 41);
  font-weight: 400;
}
.pieLabelSelected{
  margin: 10px 0;
  color: #5086fa;
  font-weight: '800';
}
.MuiTableBody-root td {
  font-size: .75rem !important;
  white-space: nowrap;
}
.MuiTableHead-root th {
  text-align: center;
}
.MuiTableCell-root {
  padding: 5px !important;
}

.main-content .container, .main-content .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}