/* 
login- #loginForm
*/

/*============================================================================================
ROOT */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  position: static !important;
}

/*============================================================================================
LOGIN PAGE */
a {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #333333;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input::-webkit-input-placeholder {
  color: #999999;
}

input:-moz-placeholder {
  color: #999999;
}

input::-moz-placeholder {
  color: #999999;
}

input:-ms-input-placeholder {
  color: #999999;
}

textarea::-webkit-input-placeholder {
  color: #999999;
}

textarea:-moz-placeholder {
  color: #999999;
}

textarea::-moz-placeholder {
  color: #999999;
}

textarea:-ms-input-placeholder {
  color: #999999;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ utility ]*/

/*==================================================================
[ Text ]*/
.txt1 {
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #999999;
}

.txt2 {
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #00ad5f;
}

.txt3 {
  font-family: Poppins-Regular;
  font-size: 15px;
  line-height: 1.4;
  color: #00ad5f;
  text-transform: uppercase;
}

/*==================================================================
[ Size ]*/
.size1 {
  width: 355px;
  max-width: 100%;
}

.size2 {
  width: calc(100% - 43px);
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0px;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.wrap-login100 {
  width: 100%;
  height: 100vh;

  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background-image: linear-gradient(hsla(340, 97%, 26%, 0.55),
      hsla(325, 51%, 62%, 0.55) 10%),
    url("./images/jayanagar-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* flex-direction: row-reverse; */
}

/*==================================================================
[ login more ]*/
.login100-more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
}

.login100-more img {
  height: 200px;
  width: 200px;
}

.login100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.hospName {
  color: black;
}

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham/Gotham-Black.otf");
}

.hospHeader {
  font-family: "Gotham", sans-serif !important;
  font-weight: bold;
  font-size: 30px;
  width: 100%;
}

.hospTagline {
  font-family: "Gotham", sans-serif !important;
  font-weight: lighter;
  font-size: 20px;
  width: 100%;
}

.form fieldset {
  border: none;
  padding: 0;
  margin: 20px 0;
  position: relative;
}

.form fieldset input {
  width: 100%;
  height: 48px;
  color: #333333;
  padding: 15px 40px 15px 15px;
  border-radius: 5px;
  font-size: 14px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
  vertical-align: top;
}

.form button {
  width: 40%;
  height: 40px;
  outline: none !important;
  background: #0099cc;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 3px 0px rgba(115, 136, 89, 0.2);
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  color: white;
}

/*--------------------
Signup
--------------------*/
.signup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 800px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  overflow: hidden;
  display: flex;
  border: 1px solid black;
}

.signup-connect,
.signup-classic {
  width: 50%;
  padding: 30px 50px;
}

.signup-connect {
  background: url("./images/pregnancy-woman-doctor-hospita.png");
  background-size: cover;
  color: black !important;
  border-right: 1px solid black;
}

.signup-connect h1 {
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 40px;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.signup-classic h2 {
  font-size: 16px;
  font-weight: normal;
  margin-top: 23px;
  margin-bottom: 43px;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.signup-classic fieldset::after {
  content: "";
  font-family: FontAwesome;
  position: absolute;
  right: 15px;
  top: 17px;
  z-index: 2;
  width: 20px;
  color: black;
  text-align: center;
}

.signup-classic fieldset.password::after {
  content: "";
}

/*==================================================================
[ Form ]*/

.login100-form {
  min-width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  padding-left: 5%;
}

.login100-form img {
  width: 300px;
  height: 170px;
}

.login100-form-title {
  font-family: "Segoe UI", Tahoma, Verdana, sans-serif !important;
  font-size: 30px;
  font-weight: bold;
  color: #555555;
  line-height: 1.2;
  text-align: left;
  padding-bottom: 20px;
  width: 100%;
  display: block;
}

/*------------------------------------------------------------------

/*===============================================================================
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: #0099cc;

  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #333333;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 992px) {
  .login100-form {
    width: 60%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .login100-more {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .login100-form {
    width: 100%;
  }

  .login100-more {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .login100-form {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 150px;
  }

  .signup {
    width: 400px;
  }

  .signup-connect,
  .signup-classic {
    width: 50%;
    padding: 20px;
  }

  .hospHeader {
    font-size: 20px;
    font-weight: 20px;
  }

  .hospTagline {
    font-size: 15px;
    font-weight: light;
    bottom: 0;
  }

  .login100-more img {
    height: 100px;
    width: 100px;
  }
}

.container {
  width: 600px;
  margin: 100px auto;
}

/* PROGRESS BAR */
.progressbar {
  counter-reset: step;
  margin-bottom: -40px;
}

.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}

.align {
  text-align: center;
}

.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #5e5d5d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}

.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

ul,
li {
  margin: 0px;
  margin-top: -25px;
  list-style-type: none;
}

.progressbar li.active {
  color: blueviolet;
}

.progressbar li.active:before {
  border-color: blueviolet;
}

.progressbar li.active+li:after {
  background-color: blueviolet;
}

.progressbar-wrapper {
  border: 1px solid white;
  /* border-top:1px solid blueviolet;
  border-left:1px solid blueviolet;
  border-right:1px solid black;
  border-bottom:1px solid black; */
  border-radius: 10px;
  box-shadow: 2px 5px rgba(245, 245, 245, 0.466);
}

.progressbar-title {
  padding: 10px;
  margin-left: 3rem;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(175, 175, 175, 0.336);
}

/* request chart */
.ht-xs-90p {
  height: 82%;
}

.p-12 {
  margin-top: -3.7rem;
}

/* sidebar */
/* Style the sidebar - fixed full height */
.header-img {
  margin-bottom: 4rem;
}

.sidebar {
  height: 100%;
  width: 240px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 16px;
}

/* Style sidebar links */
.sidebar a {
  padding: 4px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
}

/* Style links on mouse-over */
.sidebar a:hover {
  color: #f1f1f1;
  font-size: 20px;
  transition: 0.5s all;
}

/* Style the main content */
.main {
  margin-left: 160px;
  /* Same as the width of the sidenav */
  padding: 0px 10px;
}

/* Add media queries for small screens (when the height of the screen is less than 450px, add a smaller padding and font-size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}

.active a {
  /* background-color: rgba(189, 189, 199, 0.808); */
  color: #fff;
}

#li-space {
  margin-bottom: 1.7rem;
}

.Card-align {
  margin-top: -2.5rem;
  /* margin-bottom: -1rem; */
}

.signOut {
  font-size: 14px;
}

.confirm-btn {
  float: right;
  margin: 1rem;
}

tr.accepted,
tr.accepted td {
  background-color: green;
}

.tr,
tr.accepted {
  background-color: green;
}

tr.rejected,
tr.rejected td {
  background-color: red;
}

/* Access Denied Page */

.Accessdenied {
  background-image: url("./images/background.jpg");
  object-fit: cover;
  text-align: center;

  color: rgb(231, 118, 77);
}

.profilestyles {
  background-color: white;
  padding: 5rem;
  width: 70%;
  height: 100%;
  margin: 7rem auto;
}

.profilewrapper {
  background-image: url("../src/images/banner.jpg");
  object-fit: contain;
}

/* body{
  counter-reset: pageNo;
} 

footer::after {
  font-size: "14px";
  position: "fixed";
  bottom: "10px"; 
  right: "0px";
  color: "pink";
  counter-increment: pageNo;
  content:counter(pageNo);
  
} */
/* test print */
@media print {
  footer.footerCss::after {
    font-size: "1400px";
    position: "fixed";
    bottom: "10px";
    right: "0px";
    color: "pink";
    counter-increment: pageNo;
    content: counter(pageNo);

  }
}

header {
  font-size: 9px;
  color: #f00;
  text-align: center;
}

/* @page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}

@media print {
  footer {
    position: fixed;
    bottom: 0;
  }
  header {
    position: fixed;
    top: 0;
    overflow: avoid;
  }

  .content-block,
  p {
    page-break-inside: avoid;
    position: relative;
    width: 100%;
    top: 1em;
    left: 0px;
    right: 0px;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .hidden-print {
    display: none;
  }
} */

.test {
  background-color: darkblue;
}


.cardsTheme1 {
  background-color: #ffffff;
  border-radius: 20px;
  /* border: 2px solid grey; */
}

.cardsTheme1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.label-view {
  overflow: scroll;
  background-color: #F9F9F9;
  box-shadow: 0px 10px 8px #DADADA;
  border-radius: 5%
}